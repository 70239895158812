// Bootstrap Framework
import "regenerator-runtime/runtime";
import "./scss/index.scss";
import "popper.js";
import "bootstrap";

import "jquery-match-height";
import "slick-carousel";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import ScrollReveal from "scrollreveal";

import axios from "axios";
import Vue from "vue";

import {
    ValidationProvider,
    ValidationObserver,
    extend,
    localize,
} from "vee-validate";
import {
    required,
    email,
    numeric,
    max,
    alpha,
    alpha_spaces,
    regex,
} from "vee-validate/dist/rules";
import { Modal } from "bootstrap";

extend("required", required);
extend("email", email);
extend("numeric", numeric);
extend("max", max);
extend("alpha", alpha);


let locale = document.getElementById("js-lang").value;
localize(locale, require(`vee-validate/dist/locale/${locale}.json`));

$(function () {

    //#region CONFIGURAZIONI

    const Lang = $("[name*=lang]").attr("id");

    //#endregion

    //#region hover navbar dropdowns
    const $dropdown = $('#navbarMain .dropdown');
    const $dropdownToggle = $('#navbarMain .dropdown-toggle');
    const $dropdownMenu = $('#navbarMain .dropdown-menu');
    const showClass = 'show';

    $(window).on('load resize', function () {
        if (this.matchMedia('(min-width: 992px)').matches) {
            $dropdown.hover(
                function () {
                    const $this = $(this);
                    $this.addClass(showClass);
                    $this.find($dropdownToggle).attr('aria-expanded', 'true');
                    $this.find($dropdownMenu).addClass(showClass);
                },
                function () {
                    const $this = $(this);
                    $this.removeClass(showClass);
                    $this.find($dropdownToggle).attr('aria-expanded', 'false');
                    $this.find($dropdownMenu).removeClass(showClass);
                }
            );
            $dropdown.find('.dropdown-toggle').click(function () {
                const $this = $(this);
                location.href = $this.attr('href');
            });
        } else {
            $dropdown.off('mouseenter mouseleave');
        }
    });
    //#endregion

    //#region home header slider

    $(".header__slider").slick({
        arrows: true,
        dots: false,
        infinite: true,
        draggable: false,
        autoplay: true,
        autoplaySpeed: 14000,
        fade: true,
        pauseOnHover: false,
        pauseOnFocus: false,
    });

    $(".tab").on("mouseenter", function () {
        let index = $(this).parent().index() + 1;
        $(".header__slider").slick("slickGoTo", index);
    });

    //#endregion

    //#region promo match heights

    $(".promo__title").matchHeight();
    $(".promo__abstract").matchHeight();
    $(".btn btn-outline-light").matchHeight();

    //#endregion

    //#region box match heights
    $(".box").matchHeight();
    //#endregion

    //#region scrollreveal
    ScrollReveal().reveal(".framed");
    ScrollReveal().reveal(".banner");
    ScrollReveal().reveal(".box", { interval: 200 });
    //#endregion

    //#region gallery
    $(".gallery__slider").slick({
        dots: false,
        nextArrow: ".gallery__arrow--next",
        prevArrow: ".gallery__arrow--prev",
    });
    //#endregion

    //#region booking form
    $('.btn-booking').on('click', function (e) {
        e.preventDefault();
        $('.booking__wrapper').addClass('open');
    });
    const elBookingForm = document.getElementById("bookingForm");
    if (elBookingForm) {
        const bookingForm = new Vue({
            el: "#bookingForm",
            data() {
                return {
                    formData: {
                        formName: "BOOKING",
                        treatment: "",
                        name: "",
                        birthday: "",
                        phone: "",
                        email: "",
                        address: "",
                        zipCode: "",
                        city: "",
                        check_in_date: "",
                        check_out_date: "",
                        notes: "",
                        first_visit: "",
                        newsletter: "",
                        privacy: ""
                    },
                    sent: false,
                    sending: false,
                };
            },
            components: { ValidationProvider, ValidationObserver },
            methods: {
                onSubmit() {
                    this.sending = true;
                    var formData = new FormData();
                    for (let key in this.formData) {
                        formData.append(key, this.formData[key]);
                    }
                    axios
                        .post(window.location, formData)
                        .then((res) => {
                            this.$refs.quoteFormAnchor.scrollIntoView(false);
                            this.sent = true;
                            this.sending = false;
                        })
                        .catch((err) => {
                            this.sending = false;
                        });
                },
                closeBooking() {
                    $('.booking__wrapper').removeClass('open');
                }
            },
            created() {
                let qs = new URLSearchParams(window.location.search);
                this.formData.message = qs.get("message");
                // to test faster
                /* this.formData.name = "";
                this.formData.surname = "";
                this.formData.company = "";
                this.formData.sector = "";
                this.formData.customerType = "";
                this.formData.address = "";
                this.formData.zipCode = "";
                this.formData.city = "";
                this.formData.province = "";
                this.formData.country = "";
                this.formData.email = "";
                this.formData.website = "";
                this.formData.phone = "";
                this.formData.message = "";
                this.formData.privacy = ""; */
                // end
            },
        });
    }
    //#endregion

    //#region contacts form
    const elContactsForm = document.getElementById("contactsForm");
    if (elContactsForm) {
        const contactsForm = new Vue({
            el: "#contactsForm",
            data() {
                return {
                    formData: {
                        formName : "CONTACTS",
                        name: "",
                        surname: "",
                        address: "",
                        phone: "",
                        email: "",
                        question: "",
                        privacy: ""
                    },
                    sent: false,
                    sending: false,
                };
            },
            components: { ValidationProvider, ValidationObserver },
            methods: {
                onSubmit() {
                    this.sending = true;
                    var formData = new FormData();
                    for (let key in this.formData) {
                        formData.append(key, this.formData[key]);
                    }
                    axios
                        .post(window.location, formData)
                        .then((res) => {
                            //this.$refs.quoteFormAnchor.scrollIntoView(false);
                            this.sent = true;
                            this.sending = false;
                        })
                        .catch((err) => {
                            this.sending = false;
                        });
                }
            },
            created() {
                let qs = new URLSearchParams(window.location.search);
                this.formData.question = qs.get("question");
                // to test faster
                /* this.formData.name = "";
                this.formData.surname = "";
                this.formData.company = "";
                this.formData.sector = "";
                this.formData.customerType = "";
                this.formData.address = "";
                this.formData.zipCode = "";
                this.formData.city = "";
                this.formData.province = "";
                this.formData.country = "";
                this.formData.email = "";
                this.formData.website = "";
                this.formData.phone = "";
                this.formData.message = "";
                this.formData.privacy = ""; */
                // end
            },
        });
    }
    //#endregion

    //#region  rating structures
    const rating_structures = document.getElementById("div-rating-structures")
    if (rating_structures) {
        const rating_structures = new Vue({
            el: "#div-rating-structures",
            data() {
                return {
                    EntityId: $("[id*=EntityId]").val(),
                    Limit: 100,
                    Page: 1,
                    LanguageId: Lang,
                    LastRating: "",
                    selected: undefined,
                    StructuresAll: [],
                    Structures: []
                };
            },
            async mounted() {

                var entities = await this.asyncGetEntitiesByFilters(this.EntityId, this.Limit, this.Page, this.LanguageId);

                await this.GetStructures(entities);
            },
            methods: {
                FilterByRating: function (event, rating) {
                    event.preventDefault();

                    if (rating == this.LastRating) {
                        this.Structures = this.StructuresAll;
                        this.LastRating = "";
                        this.selected = undefined;
                    }
                    else {

                        this.Structures = [];
                        this.LastRating = rating;
                        this.selected = rating;

                        for (var i = 0; i < this.StructuresAll.length; i++) {
                            if (this.StructuresAll[i].Rating == rating) {
                                this.Structures.push(this.StructuresAll[i]);
                            }
                        }
                    }
                },
                ResetFilter: function (event) {
                    event.preventDefault();

                    this.Structures = this.StructuresAll;
                    this.selected = undefined;
                },
                asyncGetEntitiesByFilters(_EntityId, _Limit, _Page, _LanguageId) {
                    return new Promise((resolve, reject) => {
                        axios({
                            method: 'get',
                            url: '/GetEntitiesByFilters',
                            params: {
                                EntityId: _EntityId,
                                Limit: _Limit,
                                Page: _Page,
                                LanguageId: _LanguageId
                            }
                        })
                            .then(response => {
                                //console.log(response.data);
                                resolve(response.data);
                            })
                            .catch(error => {
                                console.log(error);
                            })
                            .finally(() => this.loading = false)
                    })
                },
                GetCssClassFromCategory(color) {

                    var retval = "";
                    console.log(color)

                    switch (color) {
                        case "cure_inalatorie":
                            retval = "promo--info";
                            break;
                        case "cure_pelle":
                            retval = "promo--danger";
                            break;
                        case "cure_bambini":
                            retval = "promo--warning";
                            break;
                        case "riabilitazione_respiratoria":
                            retval = "promo--success";
                            break;
                        case "generico":
                            retval = "promo--primary";
                            break;
                    }

                    return retval;
                },
                async GetImage(ImageId, LanguageId) {

                    return new Promise((resolve, reject) => {
                        axios({
                            method: 'get',
                            url: '/GetSlugImageByEntityId',
                            params: {
                                EntityId: ImageId,
                                LanguageId: LanguageId
                            }
                        })
                            .then(response => {
                                resolve("/uploads/" + response.data);
                            })
                            .catch(error => {
                               // console.log(error);
                            })
                            .finally(() => this.loading = false)
                    })
                },
                async GetLink(entityId, LanguageId) {

                    return new Promise((resolve, reject) => {
                        axios({
                            method: 'get',
                            url: '/GetLinkByEntityId',
                            params: {
                                EntityId: entityId,
                                LanguageId: LanguageId
                            }
                        })
                            .then(response => {
                                resolve(response.data);
                            })
                            .catch(error => {
                               // console.log(error);
                            })
                            .finally(() => this.loading = false)
                    })
                },
                async GetStructures(entities) {

                    for (var i = 0; i < entities.length; i++) {

                        if (entities[i] && entities[i].ContentDecoded && entities[i].GenericContentDecoded) {

                            var _structure = {
                                EntityId: entities[i].EntityId,
                                Rating: entities[i].GenericContentDecoded["structure rating"],
                                Type: entities[i].ContentDecoded.structure.type,
                                Title: entities[i].ContentDecoded.structure.title,
                                Subtitle: entities[i].ContentDecoded.structure.subtitle,
                                Color: this.GetCssClassFromCategory(entities[i].GenericContentDecoded.color),
                                ImgSrc: await this.GetImage(entities[i].GenericContentDecoded["Structure Image"][0].entityId, this.LanguageId),
                                Link: entities[i].GenericContentDecoded["Structure Link"] && entities[i].GenericContentDecoded["Structure Link"][0] ? await this.GetLink(entities[i].GenericContentDecoded["Structure Link"][0].entityId, this.LanguageId) : ""
                            };

                            this.Structures.push(_structure);
                            this.StructuresAll.push(_structure);
                        }
                    }
                },
                async GetEntityInfo(_EntityId) {

                    return new Promise((resolve, reject) => {
                        axios({
                            method: 'get',
                            url: '/GetEntityInfo',
                            params: {
                                EntityId: _EntityId,
                                LanguageId: this.LanguageId
                            }
                        })
                            .then(response => {
                                resolve(response.data);
                            })
                            .catch(error => {
                                // console.log(error);
                            })
                            .finally(() => this.loading = false)
                    })
                }
            }
        });
    }
    //#endregion

    //#region News-Research

    const news_research_post = document.getElementById("div-news-research-post");
    if (news_research_post) {
        const news_research_post = new Vue({
            el: "#div-news-research-post",
            data() {
                return {
                    ElementSchemaKey: $("[id*=ElementSchemaKey]").val(),
                    RelationshipType: $("[id*=RelationshipType]").val(),
                    Limit: 6,
                    Page: 1,
                    LanguageId: Lang,
                    Posts: [],
                    NoMoreItem: false,
                    LastCategory: "",
                    selected: undefined,
                };
            },
            async mounted() {
              
                var entities = await this.asyncGetPostNewsResearch(this.ElementSchemaKey, this.LanguageId, this.Page, this.Limit, "", "");
                if (entities) {
                     await this.GetPosts(entities);
                }
            },
            methods: {
                asyncGetPostNewsResearch: function(_ElementSchemaKey, _LanguageId, _Page, _Limit, _FieldName, _FilterValue) {
                    
                    return new Promise((resolve, reject) => {
                        axios({
                            method: 'get',
                            url: '/GetPostNewsResearch',
                            params: {
                                ElementSchemaKey: _ElementSchemaKey,
                                Limit: _Limit,
                                Page: _Page,
                                LanguageId: _LanguageId,
                                FieldName: _FieldName,
                                FilterValue: _FilterValue
                                }
                            })
                            .then(response => {
                                //console.log(response.data);
                                resolve(response.data);
                            })
                            .catch(error => { console.log(error); })
                            .finally(() => this.loading = false)
                    })
                },
                async FilterByCategory(event, id) {
                    event.preventDefault();
                    //console.log("Categoria selezionata: " + id);
                    
                    if (id == this.LastRating) {
                        this.LastCategory = "";
                        this.selected = undefined;
                    }
                    else {
                        this.LastCategory = id;
                        this.selected = id;
                        this.Page = 1;
                    }

                    this.Posts = [];
                    this.NoMoreItem = false;
                    var entities = await this.asyncGetPostNewsResearch(this.ElementSchemaKey, this.LanguageId, this.Page, this.Limit, this.RelationshipType, id);
                    if (entities) {
                         await this.GetPosts(entities);
                    }
                },
                async ResetFilter(event) {
                    event.preventDefault();

                    this.selected = undefined;
                    this.Posts = [];
                    this.Page = 1;
                    this.NoMoreItem = false;
                    var entities = await this.asyncGetPostNewsResearch(this.ElementSchemaKey, this.LanguageId, this.Page, this.Limit, "", "");
                    if (entities) {
                        await this.GetPosts(entities);
                    }
                },
                GetCssClassFromCategory(color) {

                    var retval = "";

                    switch (color) {
                        case "cure_inalatorie":
                            retval = "promo--info";
                            break;
                        case "cure_pelle":
                            retval = "promo--danger";
                            break;
                        case "cure_bambini":
                            retval = "promo--warning";
                            break;
                        case "riabilitazione_respiratoria":
                            retval = "promo--success";
                            break;
                        case "generico":
                            retval = "promo--primary";
                            break;
                    }

                    return retval;
                },
                StripHTML(html) {
                    return html.replace(/<[^>]*>?/gm, '');
                },
                async GetImage(ImageId, LanguageId) {

                    return new Promise((resolve, reject) => {
                        axios({
                            method: 'get',
                            url: '/GetSlugImageByEntityId',
                            params: {
                                EntityId: ImageId,
                                LanguageId: LanguageId
                            }
                        })
                            .then(response => {
                                resolve("/uploads/" + response.data);
                            })
                            .catch(error => {
                                // console.log(error);
                            })
                            .finally(() => this.loading = false)
                    })
                },
                async GetLink(entityId, LanguageId) {

                    return new Promise((resolve, reject) => {
                        axios({
                            method: 'get',
                            url: '/GetLinkByEntityId',
                            params: {
                                EntityId: entityId,
                                LanguageId: LanguageId
                            }
                        })
                        .then(response => {
                            resolve(response.data);
                        })
                        .catch(error => {
                            // console.log(error);
                        })
                        .finally(() => this.loading = false)
                    })
                },
                async GetPosts(entities) {

                    for (var i = 0; i < entities.length; i++) {
                        //console.log(entities[i]);

                        if (entities[i] && entities[i].ContentDecoded && entities[i].GenericContentDecoded) {

                            var _FacebookSharing = (entities[i].GenericContentDecoded.facebook && entities[i].GenericContentDecoded.facebook.sharing) ? entities[i].GenericContentDecoded.facebook.sharing : false;
                            var _FacebookTitle = (_FacebookSharing) ? this.StripHTML(entities[i].ContentDecoded.title) : "";
                            var _FacebookShareHref = (_FacebookSharing) ? ("https://www.termeditabiano.it/" + this.LanguageId + "/" + entities[i].ContentDecoded.slug) : "";
                            var _FacebookSharingLink = (_FacebookSharing) ? "https://www.facebook.com/sharer/sharer.php?u=" + _FacebookShareHref + "&" + _FacebookTitle : "";
                            var _CategoryInfo = (entities[i].GenericContentDecoded.category && entities[i].GenericContentDecoded.category[0] && entities[i].GenericContentDecoded.category[0].entityId) ? await this.GetEntityInfo(entities[i].GenericContentDecoded.category[0].entityId) : "";

                            var _post = {
                                  EntityId: entities[i].EntityId,
                                  Price: entities[i].ContentDecoded.price ? entities[i].ContentDecoded.price : "",
                                  Title: entities[i].ContentDecoded.title,
                                  FacebookSharing: _FacebookSharing,
                                  FacebookTitle: _FacebookTitle,
                                  FacebookShareHref: _FacebookShareHref,
                                  FacebookSharingLink: _FacebookSharingLink,
                                  Abstract: entities[i].ContentDecoded.abstractcontent,
                                  Color: (_CategoryInfo && _CategoryInfo.GenericContentDecoded && _CategoryInfo.GenericContentDecoded.color) ? this.GetCssClassFromCategory(_CategoryInfo.GenericContentDecoded.color) : "",
                                  ImgSrc: await this.GetImage(entities[i].GenericContentDecoded.header.preview[0].entityId, this.LanguageId),
                                  Link: "../" + this.LanguageId + "/" + entities[i].ContentDecoded.slug
                            };
                            
                            this.Posts.push(_post);
                        }
                    }
                },
                async GetEntityInfo(_EntityId) {

                    return new Promise((resolve, reject) => {
                        axios({
                            method: 'get',
                            url: '/GetEntityInfo',
                            params: {
                                EntityId: _EntityId,
                                LanguageId: this.LanguageId
                            }
                        })
                        .then(response => {
                            resolve(response.data);
                        })
                        .catch(error => {
                            // console.log(error);
                        })
                        .finally(() => this.loading = false)
                    })
                },
                async LoadMore(event) {
                    event.preventDefault();
                    this.Page = this.Page + 1;

                    var entities = await this.asyncGetPostNewsResearch(this.ElementSchemaKey, this.LanguageId, this.Page, this.Limit);
                    if (entities) {
                        if (entities.length > 0) {
                            await this.GetPosts(entities);
                        }
                        else {
                            this.NoMoreItem = true;
                        }
                    }
                    else {
                        this.NoMoreItem = true;
                    }
                }
            }
        });
    }

    //#endregion

    ////#region Home Page Modal
    //const homepageModal = document.getElementById("homepageModal");
    //if (homepageModal) {
    //    $(document).ready(function () {
    //        var ModalShow = false;
    //        $(document).scroll(function () {
    //            var scrolltop = $(document).scrollTop();
    //            if (scrolltop >= 300 && !ModalShow) {
    //                $("#homepageModal").modal('show');
    //                ModalShow = true;
    //            }
    //        });
    //    });
    //}
    ////#endregion

});
